import React from 'react';
import { Chart } from 'react-google-charts';

function GoogleChart(props) {
  return (
    <React.Fragment>
      <br />
      <Chart
        width={'100%'}
        height={'425px'}
        chartType={props.chart_type}
        loader={<div>Loading Chart...</div>}
        data={props.data}
        mapsApiKey={props.apiKey}
        rootProps={{ 'data-testid': '1' }}
        options={{
          title: props.title,
          subtitle: props.subtitle,
          legend: {
            position: props.chart_type === 'PieChart' ? null : 'none'
          },
          chartArea: { width: '75%' },
          hAxis: {
            title: props.h_axis_title,
            slantedText: true,
            slantedTextAngle: 45
          },
          vAxis: {
            title: props.v_axis_title,
            format: props.format
          },
          trendlines: {
            0: {
              color: 'green'
            }
          },
          animation: {
            startup: 'true',
            duration: 1000,
            easing: 'in'
          },
          region: 'US',
          displayMode: 'regions',
          resolution: 'provinces',
          sliceVisibilityThreshold: 0.0275
        }}
      />
    </React.Fragment>
  );
}

export default GoogleChart;
