import React from 'react';

const Spinner = (props) => {
  return (
    <svg
      {...props}
      xmlSpace="preserve"
      viewBox="0 0 100 100"
      y={0}
      x={0}
      id="Layer_1"
      style={{
        height: '100%',
        width: '100%',
        background: 'none',
        shapeRendering: 'auto'
      }}
      width="96px"
      height="96px"
    >
      <g
        className="ldl-scale"
        style={{
          transformOrigin: '50% 50%',
          transform: 'rotate(0deg) scale(1, 1)'
        }}
      >
        <g className="ldl-ani">
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.725926s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M43.331 88.236L10 74.862V25.138l33.331 13.374z"
                strokeMiterlimit={10}
                strokeLinejoin="round"
                strokeWidth={3.5}
                stroke="#323232"
                fill="#f7b26a"
                style={{
                  fill: 'rgb(247, 178, 106)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.774074s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M29.981 74.88l-12.437-4.991"
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeWidth={3.5}
                stroke="#323232"
                fill="#f7b26a"
                strokeLinejoin="round"
                style={{
                  fill: 'rgb(247, 178, 106)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.822222s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M25.874 66.232l-8.33-3.343"
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeWidth={3.5}
                stroke="#323232"
                fill="#f7b26a"
                strokeLinejoin="round"
                style={{
                  fill: 'rgb(247, 178, 106)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.87037s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M43.331 88.236L90 74.862V25.138L43.331 38.512z"
                strokeMiterlimit={10}
                strokeLinejoin="round"
                strokeWidth={3.5}
                stroke="#323232"
                fill="#f7b26a"
                style={{
                  fill: 'rgb(247, 178, 106)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.918519s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M90 25.138L56.669 11.764 10 25.138h0l33.331 13.374L90 25.138z"
                strokeMiterlimit={10}
                strokeLinejoin="round"
                strokeWidth={3.5}
                stroke="#323232"
                fill="#f7b26a"
                style={{
                  fill: 'rgb(247, 178, 106)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -0.966667s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M40.681 16.345l-13.185 3.779 33.331 13.374 13.185-3.778z"
                strokeLinejoin="round"
                stroke="#323232"
                fill="#f4e6c8"
                strokeMiterlimit={10}
                strokeWidth={3.5}
                style={{
                  fill: 'rgb(244, 230, 200)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -1.01481s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M74.012 42.065l-13.185 3.778V33.498l13.185-3.778z"
                strokeLinejoin="round"
                stroke="#323232"
                fill="#f4e6c8"
                strokeMiterlimit={10}
                strokeWidth={3.5}
                style={{
                  fill: 'rgb(244, 230, 200)',
                  stroke: 'rgb(50, 50, 50)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -1.06296s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M54.984 84.897L90 74.862V25.138l-15.773 4.52z"
                strokeLinejoin="round"
                stroke="#000"
                opacity={0.2}
                strokeMiterlimit={10}
                strokeWidth={3.5}
                style={{
                  fill: 'rgb(0, 0, 0)',
                  stroke: 'rgb(0, 0, 0)'
                }}
              />
            </g>
          </g>
          <g className="ldl-layer">
            <g
              className="ldl-ani"
              style={{
                transformOrigin: '50px 50px',
                opacity: 1,
                animation:
                  '1.11111s linear -1.11111s infinite normal forwards running fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d'
              }}
            >
              <path
                d="M74.137 29.684L90 25.138h0l-15.863-6.365z"
                strokeLinejoin="round"
                stroke="#000"
                opacity={0.2}
                strokeMiterlimit={10}
                strokeWidth={3.5}
                style={{
                  fill: 'rgb(0, 0, 0)',
                  stroke: 'rgb(0, 0, 0)'
                }}
              />
            </g>
          </g>
        </g>
      </g>
      <style id="fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d" data-anikit="">
        {
          '@keyframes fade-9bfc6405-2e85-4a23-9d07-d9672a607a3d\n{\n  0% {\n    animation-timing-function: cubic-bezier(0.2057,0.573,0.3723,0.9184);\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}'
        }
      </style>
    </svg>
  );
};
export default Spinner;
