import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div>
        <h2>Home</h2>
        <p>
          {' '}
          Eu nulla velit ipsum in fugiat ullamco sit tempor.Enim id aliqua duis
          non pariatur id minim ipsum sit labore eu proident nulla mollit sint
          nulla.Laboris laborum Lorem deserunt ad nisi ipsum pariatur do officia
          ullamco nulla anim pariatur voluptate.Enim culpa est labore sunt non
          consectetur officia ullamco do.Aliquip incididunt voluptate
          consectetur ut nostrud culpa veniam consectetur eiusmod velit officia
          adipisicing amet enim laborum commodo.Consequat in sint sit fugiat
          ullamco laborum incididunt aliqua ea incididunt consequat Lorem sint.{' '}
        </p>
      </div>
    );
  }
}

export default Home;
