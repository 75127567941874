import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styles from 'styled-components';
import DatePicker from './components/DatePicker';
import zipToLocation from './data/zipToLocation';
import JumboTron from './components/JumboTron';
import useSpinner from './components/Spinner/useSpinner';

const Styles = styles.div`
  .jumbotron {
    width: 100%;
  }
`;

const Marker = ({ children }) => children;

export default function Map(props) {
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const [destinations, setDestinations] = useState([]);
  const [recordCount, setRecordCount] = useState(0);

  function dateRangeChange(dateRange) {
    getMapData(dateRange);
  }

  async function getMapData(dateRange) {
    const paketWatchUrl = `${
      process.env.REACT_APP_PAKETWATCH_API_PATH
    }reports?startDate=${dateRange[0]
      .toISOString()
      .substring(0, 10)}&endDate=${dateRange[1]
      .toISOString()
      .substring(0, 10)}&carrier=FedEx&zips=true`; //<<<<<<<<<< ZIPCODE query string

    try {
      showSpinner();
      const response = await fetch(paketWatchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.user.signInUserSession.idToken.jwtToken
        }
      });
      const data = await response.json();
      const { zipCodes, records_in_range } = data;

      if (!records_in_range) {
        setDestinations([]);
        setRecordCount(records_in_range);
        throw new RangeError('No records in range');
      }
      let geoPoints = zipCodes
        .filter((geoPoint) => geoPoint.Recipient_Postal_Code !== '')
        .map(function (geoPoint) {
          const location = zipToLocation.find(
            (element) => element.fields.zip === geoPoint.Recipient_Postal_Code
          );
          if (!location) {
            return;
          }
          if (!location.hasOwnProperty('fields')) {
            return;
          }
          const lat = location.fields.latitude;
          const lng = location.fields.longitude;
          const result = {
            lat: lat,
            lng: lng,
            trackingNumber: geoPoint.Shipment_Tracking_Number
          };
          return result;
        })
        .filter((geoPoint) => geoPoint != null);
      setDestinations(geoPoints);
      setRecordCount(records_in_range);
    } catch (error) {
      alert(error);
    }
    hideSpinner();
  }
  return (
    <Styles>
      <React.Fragment>
        {spinner}
        <JumboTron
          title="Shipment Locations"
          record_count={recordCount}
        ></JumboTron>
        <DatePicker updateDateRange={dateRangeChange} />
        <div style={{ height: '100vh', width: '100%' }}>
          {props.apiKey && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: props.apiKey
              }}
              defaultCenter={{ lat: 38.5, lng: -98.5556 }}
              defaultZoom={4.7}
              // onChildClick={this.onChildClick}
            >
              {destinations.map((geoPoint) => (
                <Marker
                  key={geoPoint.trackingNumber}
                  lat={geoPoint.lat}
                  lng={geoPoint.lng}
                  text={`Tracking Number: ${geoPoint.trackingNumber}`}
                >
                  <div>
                    <img src="/parcel.png" alt="*"></img>
                  </div>
                </Marker>
              ))}
            </GoogleMapReact>
          )}
        </div>
      </React.Fragment>
    </Styles>
  );
}
