import React from 'react';
import './Spinner.css';
import SpinnerIcon from './SpinnerIcon';

const Spinner = (props) => {
  const styled = {
    background: 'rgba(0,0,0,.66)',
    height: '100vh',
    width: '100vw',
    position: 'fixed'
  };
  return (
    <div className="Spinner" style={styled}>
      <SpinnerIcon />
    </div>
  );
};
export default Spinner;
