import React, { useState } from 'react';
import styles from 'styled-components';
import DatePicker from './components/DatePicker';
import JumboTron from './components/JumboTron';
import KpiCard from './components/KpiCard';
import useSpinner from './components/Spinner/useSpinner';

const Styles = styles.div`
`;

export default function KPIs(props) {
  const [_kpis, setKpis] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  function dateRangeChange(dateRange) {
    getKPIs(dateRange);
  }

  async function getKPIs(dateRange) {
    showSpinner();
    const url = `${
      process.env.REACT_APP_PAKETWATCH_API_PATH
    }reports?startDate=${dateRange[0]
      .toISOString()
      .substring(0, 10)}&endDate=${dateRange[1]
      .toISOString()
      .substring(0, 10)}&carrier=FedEx&kpis=true`; //<<<<<<<<<< KPI query string
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.user.signInUserSession.idToken.jwtToken
        }
      });
      const data = await response.json();
      const { kpis, records_in_range } = data;
      if (!records_in_range) {
        setKpis([]);
        setRecordCount(records_in_range);
        throw new RangeError('No records in range');
      }
      setKpis(kpis);
      setRecordCount(data.records_in_range);
    } catch (error) {
      alert(error);
    }
    hideSpinner();
  }

  return (
    <div>
      {spinner}
      <Styles>
        <React.Fragment>
          <JumboTron title="KPIs" record_count={recordCount}></JumboTron>
          <DatePicker updateDateRange={dateRangeChange} />
          <div className="card-columns">
            {_kpis.map((card, index) => (
              <KpiCard
                key={index}
                title={card.title}
                text={card.text}
                value={card.value}
                link={card.link}
              ></KpiCard>
            ))}
          </div>
        </React.Fragment>
      </Styles>
    </div>
  );
}
