import React, { useState } from 'react';
import styles from 'styled-components';
import DatePicker from './components/DatePicker';
import JumboTron from './components/JumboTron';
import GoogleChart from './components/GoogleChart';
import useSpinner from './components/Spinner/useSpinner';

const Styles = styles.div`
`;

export default function KpiChart(props) {
  const [reports, setReports] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  function dateRangeChange(dateRange) {
    getKpiCharts(dateRange);
  }

  async function getKpiCharts(dateRange) {
    showSpinner();
    const url = `${
      process.env.REACT_APP_PAKETWATCH_API_PATH
    }reports?startDate=${dateRange[0]
      .toISOString()
      .substring(0, 10)}&endDate=${dateRange[1]
      .toISOString()
      .substring(0, 10)}&carrier=FedEx&charts=true`; //<<<<<<<<<< CHARTS query string
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.user.signInUserSession.idToken.jwtToken
        }
      });
      const data = await response.json();
      const { reports, records_in_range } = data;
      if (!records_in_range) {
        setReports([]);
        setRecordCount(records_in_range);
        throw new RangeError('No records in range');
      }
      setReports(reports);
      setRecordCount(records_in_range);
    } catch (error) {
      alert(error);
    }
    hideSpinner();
  }

  return (
    <Styles>
      <React.Fragment>
        {spinner}
        <JumboTron title="KPI Charts" record_count={recordCount}></JumboTron>
        <DatePicker updateDateRange={dateRangeChange} />
        {reports.map((report, index) => (
          <GoogleChart
            key={index}
            title={report.title}
            subtitle={report.subtitle}
            h_axis_title={report.h_axis_title}
            v_axis_title={report.v_axis_title}
            data={report.data}
            format={report.format}
            chart_type={report.chart_type}
            apiKey={props.apiKey}
          ></GoogleChart>
        ))}
      </React.Fragment>
    </Styles>
  );
}
