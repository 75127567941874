import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavigationBar from './components/NavigationBar.jsx';
import { Container, Button, Form } from 'react-bootstrap';
import { Layout } from './components/Layout.jsx';
// import Login from './Login.jsx';
import Home from './Home.jsx';
import { NoMatch } from './NoMatch.js';
import { Reports } from './Reports.js';
import Upload from './Upload.js';
import { Pallet } from './Pallet.js';
import { Parcel } from './Parcel.js';
import IntervalChart from './IntervalChart.js';
import KpiChart from './KpiChart.js';
import KPIs from './KPIs.js';
import Map from './Map.js';
import Account from './Account.jsx'
import { Auth, Hub } from 'aws-amplify';
import { DateContext } from './components/DateContext';

const initialFormState = {
  username: '',
  password: '',
  email: '',
  authCode: '',
  authenticatedUser: null
};

const initialDateRange = [
  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  new Date()
];

export default function App() {
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [formState, updateFormState] = useState(initialFormState);
  const [user, setUser] = useState(null);
  const [googleApiKey, setGoogleApiKey] = useState(null);

  useEffect(() => {
    async function updateAuth() {
      try {
        const currentUser = await checkUser();
        if (!googleApiKey) await getGoogleApiKey(currentUser);
        await setAuthListener();
      } catch (error) {}
    }
    updateAuth();
  }, []);

  async function checkUser() {
    let currentUser;
    try {
      currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
      if (currentUser !== authenticatedUser) {
        updateFormState(() => ({
          ...formState,
          authenticatedUser: currentUser
        }));
      }
    } catch (error) {
      console.log(error);
    }
    return currentUser;
  }

  async function getGoogleApiKey(currentUser) {
    const googleApiUrl = `${process.env.REACT_APP_PAKETWATCH_API_PATH}apikey?googleapikey=true`; //<<<<<<<<<< Google API query string
    try {
      const response = await fetch(googleApiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentUser.signInUserSession.idToken.jwtToken
        }
      });
      const data = await response.json();
      const { googleApiKey } = data;
      setGoogleApiKey(googleApiKey);
    } catch (error) {
      console.log(error);
    }
  }

  async function setAuthListener() {
    Hub.listen('auth', (data) => {
      const event = data.payload.event;
      switch (data.payload.event) {
        case 'signOut':
          // console.log('signing out...');
          // console.log(event);
          updateFormState(() => ({
            ...formState,
            username: '',
            password: '',
            email: '',
            authenticatedUser: null
          }));
          break;
        default:
          break;
      }
    });
  }

  function onChangeHandler(event) {
    event.persist();
    updateFormState(() => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const { authenticatedUser } = formState;

  async function signIn() {
    try {
      const { username, password } = formState;
      const user = await Auth.signIn(username, password);
      updateFormState(() => ({
        ...formState,
        authenticatedUser: user
      }));
      // console.log(user);
    } catch (errors) {
      alert(errors.message);
    }
  }
  const authProps = { ...formState };
  const userProps = { ...user };
  return (
    <div className="App">
      {!authenticatedUser && (
        <Container fluid>
          <Form>
            <Form.Group>
              <Form.Label>User Name</Form.Label>
              <Form.Control
                id="username"
                name="username"
                onChange={onChangeHandler}
                placeholder="Enter username"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Label>Password</Form.Label>
              <Form.Control
                id="password"
                name="password"
                onChange={onChangeHandler}
                type="password"
                placeholder="Password"
              />
              <Form.Text className="text-muted">
                <i>forgot password</i>
              </Form.Text>
            </Form.Group>
            <Button
              onClick={(event) => {
                event.preventDefault();
                signIn();
              }}
              variant="primary"
              type="submit"
            >
              Sign In
            </Button>
          </Form>
        </Container>
      )}
      {authenticatedUser && (
        <React.Fragment>
          <NavigationBar auth={authProps} />
          <Layout>
            <Router>
              <Switch>
                <Route exact path="/" render={(props) => <Home />} />
                {/* <Route
                  path="/login"
                  render={(props) => <Login user={userProps} />}
                /> */}
                <Route
                  path="/upload"
                  render={(props) => <Upload user={userProps} />}
                />
                <Route path="/reports" render={(props) => <Reports />} />
                <Route
                  path="/pallet"
                  render={(props) => <Pallet user={userProps} />}
                />
                <Route path="/parcel" render={(props) => <Parcel />} />
                <DateContext.Provider
                  value={{
                    dateRange: dateRange,
                    setDateRange: setDateRange
                  }}
                >
                  <Route
                    path="/kpis"
                    render={(props) => <KPIs user={userProps} />}
                  />
                  <Route
                    path="/interval"
                    render={(props) => <IntervalChart user={userProps} />}
                  />
                  <Route
                    path="/charts"
                    render={(props) => (
                      <KpiChart user={userProps} apiKey={googleApiKey} />
                    )}
                  />
                  <Route
                    path="/map"
                    render={(props) => (
                      <Map user={userProps} apiKey={googleApiKey} />
                    )}
                  />
                  <Route
                    path="/account"
                    render={(props) => (
                      <Account user={userProps} apiKey={googleApiKey} />
                    )}
                  />
                </DateContext.Provider>
                <Route component={NoMatch} />
              </Switch>
            </Router>
          </Layout>
        </React.Fragment>
      )}
    </div>
  );
}
