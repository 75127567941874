import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Container,
  Table,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import styles from 'styled-components';
import PalletRendering from './PalletRendering.jsx';
import useSpinner from './Spinner/useSpinner';
// import { CSVDownloader } from 'react-papaparse';
import { CSVReader } from 'react-papaparse';
import { jsonToCSV } from 'react-papaparse';
// import Isomer from 'isomer';

const Styles = styles.div`
    // .form-control:valid {
    //     background-color:  #96d3ec !important;
    // }
`;

export default function CartonInput(props) {
  const [palletDims, setPalletDims] = useState({
    length: 48,
    width: 40,
    height: 5.5,
    weight: 48
  });
  const [cartonDims, setCartonDims] = useState({
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    qty: 1
  });
  const [stackHeight, setStackHeight] = useState({
    height: 52
  });
  const [palletQuantity, setPalletQuantity] = useState(0);
  const [windowDims, setWindowDims] = useState({
    height: window.innerWidth * 1.5,
    width: window.innerWidth
  });
  const [visualize, setVisualize] = useState(false);
  const [results, setResults] = useState([]);
  const [currentOption, setCurrentOption] = useState(0);
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(false);
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    // setWindowDims({
    //   height: window.innerWidth * 0.8 * 1.6,
    //   width: window.innerWidth * 0.8
    // });
  });

  function handleCartonChange(event) {
    event.preventDefault();
    const value = event.target.value;
    setCartonDims({ ...cartonDims, [event.target.name]: value });
  }

  function handleHeightChange(event) {
    const value = event.target.value;
    event.preventDefault();
    setStackHeight({
      height: Number(value)
    });
  }

  function handlePalletChange(event) {
    event.preventDefault();
    const value = event.target.value;
    setPalletDims({
      length: 40,
      width: 48,
      height: Number(value) === 48 ? 5.5 : 6,
      weight: Number(value)
    });
  }

  function handleCheckboxChange(event) {
    //event.preventDefault();
    const target = event.target;
    setVisualize(target.checked);
  }

  function handleOption(event) {
    event.preventDefault();
    let option = currentOption;
    const numOptions = results.length;
    if (++option >= numOptions) {
      option = 0;
    }
    setCurrentOption(option);
  }

  function downloadCsvTemplate(event) {
    event.preventDefault();
    setUploadCSV(false);
    setResults([]);
    let sampleData = [
      {
        'Carton Length': 12,
        'Carton Width': 14,
        'Carton Height': 10,
        'Carton Weight': 19,
        'Carton Quantity': 1200
      },
      {
        'Carton Length': 7,
        'Carton Width': 13,
        'Carton Height': 12,
        'Carton Weight': 31,
        'Carton Quantity': 950
      },
      {
        'Carton Length': 19,
        'Carton Width': 21,
        'Carton Height': 18,
        'Carton Weight': 15,
        'Carton Quantity': 1024
      }
    ];
    let csv = jsonToCSV(sampleData);
    if (csv === null) {
      return;
    }
    const fileName = 'PaketWatch_Upload_Template.csv';
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    const downLoad = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', downLoad);
    link.setAttribute('download', fileName);
    link.click();
  }

  function uploadCsvFile(event) {
    event.preventDefault();
    setResults([]);
    uploadCSV ? setUploadCSV(false) : setUploadCSV(true);
  }

  function downloadCsvFile() {
    setUploadCSV(false);
    // console.log(jsonData);
    let csv = jsonToCSV(jsonData);
    if (csv === null) {
      return;
    }
    const currentDateTime = new Date();
    const dateString = currentDateTime.toISOString().split('T')[0];
    const fileName = `PaketWatch_Export_${dateString}.csv`;
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    const downLoad = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', downLoad);
    link.setAttribute('download', fileName);
    link.click();
    setJsonData([]);
  }

  function handleOnDrop(rawData) {
    showSpinner();
    const requiredKeys = [
      'Carton Length',
      'Carton Width',
      'Carton Height',
      'Carton Weight',
      'Carton Quantity'
    ];
    let complaints = [];
    requiredKeys.forEach((key) => {
      if (rawData[0].meta.fields.find((element) => element === key)) {
      } else {
        complaints.push(key);
      }
    });
    if (complaints.length) {
      alert(
        `Bad Data! Missing ${JSON.stringify(complaints)} header${
          complaints.length > 1 ? 's' : ''
        }.`
      );
      return;
    }
    const data = rawData.map((datum) => datum.data);
    //console.log(data);
    const filteredData = data.filter(
      (datum) =>
        datum['Carton Length'] !== null &&
        datum['Carton Width'] !== null &&
        datum['Carton Height'] !== null &&
        datum['Carton Weight'] !== null &&
        datum['Carton Quantity'] !== null
    );
    setJsonData(getResults(filteredData));
    hideSpinner();
  }

  function getResults(filteredData) {
    filteredData.reduce((accumulatorPromise, currentItem, index, array) => {
      return accumulatorPromise.then(() => {
        return new Promise((resolve, reject) => {
          fetch(
            `${process.env.REACT_APP_PAKETWATCH_API_PATH}stack?clength=${currentItem['Carton Length']}&cwidth=${currentItem['Carton Width']}&cheight=${currentItem['Carton Height']}&cweight=${currentItem['Carton Weight']}&cqty=${currentItem['Carton Quantity']}&plength=${palletDims.length}&pwidth=${palletDims.width}&pheight=${palletDims.height}&pweight=${palletDims.weight}&sheight=${stackHeight.height}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: props.user.signInUserSession.idToken.jwtToken
              }
            }
          )
            .then((result) => result.json())
            .then((data) => {
              const { pallet_count } = data;
              const {
                actualHeight,
                grossWeight,
                layerQty,
                numLayers,
                palletQty
              } = data.stackOptions[0];
              array[index]['Layer Quantity'] = layerQty;
              array[index]['Number of Layers'] = numLayers;
              array[index]['Cartons per Pallet'] = palletQty;
              array[index]['Pallet Height'] = actualHeight;
              array[index]['Pallet Gross Weight'] = grossWeight;
              array[index]['Total Pallets'] = pallet_count;
              resolve(data);
            })
            .catch((error) => reject(error));
        });
      });
    }, Promise.resolve());
    return filteredData;
  }

  function handleOnError(err, file, inputElem, reason) {
    // console.log(err)
  }

  function handleOnRemoveFile(data) {
    setJsonData([]);
  }

  async function handleSubmit(event) {
    event.preventDefault(event);
    showSpinner();
    const paketWatchUrl = `${process.env.REACT_APP_PAKETWATCH_API_PATH}stack?clength=${cartonDims.length}&cwidth=${cartonDims.width}&cheight=${cartonDims.height}&cweight=${cartonDims.weight}&cqty=${cartonDims.qty}&plength=${palletDims.length}&pwidth=${palletDims.width}&pheight=${palletDims.height}&pweight=${palletDims.weight}&sheight=${stackHeight.height}`;
    // console.log(props.user.signInUserSession.idToken.jwtToken);
    // console.log(paketWatchUrl);
    try {
      const response = await fetch(paketWatchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.user.signInUserSession.idToken.jwtToken
        }
      });
      const data = await response.json();
      // console.log(data);
      const { stackOptions, pallet_count } = data;
      setResults(stackOptions);
      setPalletQuantity(pallet_count);
    } catch (error) {
      alert(error);
    }
    hideSpinner();
    document.getElementById('results').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div>
      {spinner}
      <Container id="container">
        <Styles>
          <Form>
            <Form.Group controlId="formCartonInput">
              <Form.Label>Carton Length:</Form.Label>
              <Form.Control
                name="length"
                style={
                  cartonDims.length
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'pink' }
                }
                onChange={handleCartonChange}
                type="number"
                placeholder="inches"
              />
              <Form.Label>Carton Width:</Form.Label>
              <Form.Control
                name="width"
                style={
                  cartonDims.width
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'pink' }
                }
                onChange={handleCartonChange}
                type="number"
                placeholder="inches"
              />
              <Form.Label>Carton Height:</Form.Label>
              <Form.Control
                name="height"
                style={
                  cartonDims.height
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'pink' }
                }
                onChange={handleCartonChange}
                type="number"
                placeholder="inches"
              />
              <Form.Label>Carton Weight:</Form.Label>
              <Form.Control
                name="weight"
                style={
                  cartonDims.weight
                    ? { backgroundColor: 'white' }
                    : { backgroundColor: 'pink' }
                }
                onChange={handleCartonChange}
                type="number"
                placeholder="lbs"
              />
              <Form.Label>Carton Quantity:</Form.Label>
              <Form.Control
                name="qty"
                onChange={handleCartonChange}
                type="number"
                placeholder="qty (optional)"
              />
              <Form.Label>Maximum Stack Height:</Form.Label>
              <Form.Control
                as="select"
                name="stackHeight"
                onChange={handleHeightChange}
              >
                <option value="52">52in (standard rack height)</option>
                <option value="96">96in (standard truck height)</option>
                <option value="72">72in (Amazon/Target required height)</option>
              </Form.Control>
              <Form.Label>Pallet Construction:</Form.Label>
              <Form.Control
                as="select"
                name="weight"
                onChange={handlePalletChange}
              >
                <option value="48">40x48x5.5 (standard-48lbs)</option>
                <option value="70">40x48x6 (CHEP-70lbs)</option>
              </Form.Control>
              <br></br>
              <Form.Check
                onChange={handleCheckboxChange}
                checked={visualize}
                type="checkbox"
                label="Show Pallet Stack"
                name="checkBox"
              />
              <br></br>
              <Button
                onClick={
                  cartonDims.length &&
                  cartonDims.width &&
                  cartonDims.height &&
                  cartonDims.weight
                    ? handleSubmit
                    : () => alert('Missing Carton Dims')
                }
                variant="primary"
              >
                Submit
              </Button>
              <DropdownButton
                as={ButtonGroup}
                title="CSV Tools"
                id="bg-nested-dropdown"
                className="float-right"
              >
                <Dropdown.Item eventKey="1" onClick={uploadCsvFile}>
                  Upload CSV Input
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  disabled={jsonData.length === 0 ? true : false}
                  onClick={downloadCsvFile}
                >
                  Download CSV Output
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3" onClick={downloadCsvTemplate}>
                  <i>Download CSV Upload Template</i>
                </Dropdown.Item>
              </DropdownButton>
              <br></br>
              <br></br>
            </Form.Group>
          </Form>
          {uploadCSV && (
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              noClick
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              config={{
                header: true,
                dynamicTyping: true
              }}
            >
              <span>
                <b>Drag CSV file here to upload.</b>
              </span>
            </CSVReader>
          )}
          {results.length > 0 && (
            <React.Fragment>
              <div id="results">
                <h3>Pallet Details</h3>
              </div>
              <div className="text-center">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Cartons Per Layer</th>
                      <th>Layers per Pallet</th>
                      <th>Cartons per Pallet</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{results[0].layerQty}</td>
                      <td>{results[0].numLayers}</td>
                      <td>{results[0].palletQty}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="text-center">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Actual Pallet Height</th>
                      <th>Gross Pallet Weight</th>
                      <th>Total Pallets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{results[0].actualHeight}</td>
                      <td>{results[0].grossWeight}</td>
                      <td>{palletQuantity}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {visualize && (
                <React.Fragment>
                  <br></br>
                  {results.length > 1 && (
                    <div>
                      <h3>
                        Pallet Option <span>{currentOption + 1}</span> of{' '}
                        <span>{results.length}</span>
                      </h3>
                      <Button
                        onClick={handleOption}
                        variant="primary"
                        type="submit"
                      >
                        {` Next >>`}
                      </Button>
                    </div>
                  )}
                  <br></br>
                  <div>
                    <PalletRendering
                      name={results[currentOption]}
                      height={
                        document.getElementById('container').offsetWidth *
                        1.1 *
                        (stackHeight.height / 52)
                      }
                      width={
                        document.getElementById('container').offsetWidth * 0.9
                      }
                    ></PalletRendering>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Styles>
      </Container>
    </div>
  );
}
