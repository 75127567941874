import React from 'react';
import CartonInput from './components/CartonInput.jsx';

export const Pallet = (props) => (
  <React.Fragment>
    <div>
      <h2>Pallet Stack Calculator</h2>
      <p> Calculate the number of cartons on a pallet</p>
    </div>
    <CartonInput user={props.user} />
  </React.Fragment>
);
