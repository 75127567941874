import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Button
} from 'react-bootstrap';

export default function Account(props) {
  const [formState, setFormState] = useState('none');
  const [credentials, setNewCredentials] = useState({
    userName: props.user.username,
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null
  });

  function changePassword() {
    setFormState('changePassword');
  }

  function setNewPassword(event) {
    event.preventDefault();
    if (!(credentials.newPassword === credentials.confirmNewPassword)) {
      alert(`New Password and Confirmation must match`);
      return;
    } else if (!credentials.newPassword) {
      alert(`No new password entered.`);
      return;
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        return Auth.changePassword(
          user,
          credentials.oldPassword,
          credentials.newPassword
        );
      })
      .then((data) => {
        alert(data);
        Auth.signOut();
      })
      .catch((error) => alert(error));
  }

  function onChangeHandler(event) {
    event.persist();
    setNewCredentials(() => ({
      ...credentials,
      [event.target.name]: event.target.value
    }));
  }

  return (
    <React.Fragment>
      <div>
        <h2>Account Settings</h2>
        <p>
          {' '}
          Update account settings for{' '}
          <span>
            <b>{props.user.username}</b>
          </span>{' '}
          here
        </p>
        <br />
      </div>
      <Container fluid>
        <DropdownButton id="dropdown-basic-button" title="Account Functions">
          <Dropdown.Item eventKey="1" onClick={changePassword}>
            Change Password
          </Dropdown.Item>
          <Dropdown.Item disabled href="#/action-2">
            Coming soon
          </Dropdown.Item>
        </DropdownButton>
        <div>
          <br />
        </div>

        {formState === 'changePassword' && (
          <Form>
            <Form.Group>
              <Form.Label>User Name</Form.Label>
              <Form.Control
                id="userName"
                name="userName"
                disabled
                placeholder={credentials.userName}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                id="oldPassword"
                name="oldPassword"
                onChange={onChangeHandler}
                type="password"
                placeholder="password"
              />
              <Form.Label>New Password</Form.Label>
              <Form.Control
                id="newPassword"
                name="newPassword"
                onChange={onChangeHandler}
                type="password"
                placeholder="password"
              />
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                id="confirmNewPassword"
                name="confirmNewPassword"
                onChange={onChangeHandler}
                type="password"
                placeholder="password"
              />
            </Form.Group>
            <Button onClick={setNewPassword} variant="primary" type="submit">
              Confirm
            </Button>
          </Form>
        )}
      </Container>
    </React.Fragment>
  );
}
