import React, { Component } from 'react';
import { Container, Jumbotron } from 'react-bootstrap';

class JumboTron extends Component {
  render() {
    return (
      <Container fluid>
        <Jumbotron fluid className="text-center" style={{ padding: 2 }}>
          <h1>{this.props.title}</h1>
          <h4>
            <p>Displaying {this.props.record_count} Records</p>
          </h4>
        </Jumbotron>
      </Container>
    );
  }
}

export default JumboTron;
