import React, { useState, useContext } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from 'styled-components';
import { DateContext } from './DateContext';

const Styles = styles.div`
  .center {
    margin: auto;
    padding: 10px;
  }
  .button {
    width: "150px"
  }
  .container { 
    position: relative;
  }
  
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
`;

export default function DatePicker(props) {
  const { dateRange, setDateRange } = useContext(DateContext);
  const [modalVisible, toggleModal] = useState(false);
  const [selectedRange, setlectRange] = useState(dateRange);
  // const [dateSummary, setDateSummary] = useState('days');

  return (
    <Styles>
      <React.Fragment>
        <Container fluid>
          <Button className="button" onClick={() => toggleModal(true)}>
            {`Start: ${dateRange[0].toISOString().substring(0, 10)}`}
            <br />
            {`End:${dateRange[1].toISOString().substring(0, 10)}`}
          </Button>
        </Container>
        {modalVisible && (
          <Modal
            size="sm"
            show={modalVisible}
            onHide={() => toggleModal(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Select Date Range:
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Calendar
                onChange={setlectRange}
                selectRange
                calendarType="US"
                value={selectedRange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setDateRange(selectedRange);
                  props.updateDateRange(selectedRange);
                  toggleModal(false);
                }}
                variant="primary"
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <br />
      </React.Fragment>
    </Styles>
  );
}
