import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { Button, Form } from 'react-bootstrap';
import TranslationTable from './data/TranslationTable';
import useSpinner from './components/Spinner/useSpinner';

const buttonRef = React.createRef();

export default function Upload(props) {
  const [sessionState, setSessionState] = useState({
    showTable: false,
    file: '*.csv',
    translatedData: [],
    haveUploadData: false,
    isUploading: false
  });
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  function handleOpenDialog(e) {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  }

  async function handleOnFileLoad(data) {
    showSpinner();
    try {
      const mappedData = data.map((datum) => datum.data);
      const translatedData = await translate(scrubParcelObjects(mappedData));
      setSessionState({
        translatedData: translatedData,
        haveUploadData: true
      });
    } catch (error) {
      console.log(error);
    }
    hideSpinner();
  }

  function handleOnError(err, file, inputElem, reason) {
    console.log(err);
  }

  function handleOnRemoveFile(data) {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  }

  // function handleRemoveFile(e) {
  //   // Note that the ref is set async, so it might be null at some point
  //   if (buttonRef.current) {
  //     buttonRef.current.removeFile(e);
  //   }
  // }

  async function asyncUpload() {
    showSpinner();
    try {
      if (sessionState.translatedData.length === 0) {
        return;
      }
      setSessionState({
        isUploading: true
      });
      let dbUpload = sessionState.translatedData;
      let chunks = [];
      while (dbUpload.length) {
        chunks.push(dbUpload.splice(0, 100));
      }
      for (const chunk of chunks) {
        let promises = chunk.reduce((accumulator, object) => {
          accumulator.push(
            fetch(`${process.env.REACT_APP_PAKETWATCH_API_PATH}shipments`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: props.user.signInUserSession.idToken.jwtToken
              },
              body: JSON.stringify(object),
              mode: 'cors'
            })
          );
          return accumulator;
        }, []);
        await Promise.all(promises).then(() => {
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 250);
          });
        });
      }
    } catch (errors) {
      alert(errors);
    }
    hideSpinner();
  }

  async function translate(scrubbedData) {
    let translatedData = [];
    scrubbedData.forEach((element) => {
      let dbItem = element;
      let keys = Object.keys(dbItem);
      keys.forEach((key) => {
        let newKey = TranslationTable[key];
        dbItem[newKey] = element[key];
        delete dbItem[key];
      });
      [
        'Shipment_Date',
        'Shipment_Delivery_Date',
        'Invoice_Date_mm_dd_yyyy'
      ].forEach((dateKey) => {
        if (isNaN(element[dateKey].getTime())) {
          element[dateKey] = '';
        } else {
          element[dateKey] = element[dateKey].toISOString().substring(0, 10);
        }
      });
      translatedData.push(dbItem);
    });
    return translatedData;
  }

  function scrubParcelObjects(rawData) {
    //console.log('@scrubParcelObjects');
    let scrubbed = [];
    rawData.forEach((object) => {
      let temp = JSON.parse(JSON.stringify(object));
      [
        'Recipient Postal Code',
        'Recipient Original Postal Code',
        'Shipper Postal Code'
      ].forEach((zipField) => {
        temp[zipField] = (function (zip) {
          if (zip.length === 4 || zip.length === 8) {
            zip = '0' + zip;
          }
          if (zip.length) {
            return zip.substring(0, 5);
          }
          return '';
        })(temp[zipField]);
      });
      temp = convertValueToNumber(temp, [
        'Shipment Freight Charge Amount USD',
        'Shipment Freight Charge Billed Currency',
        'Shipment Miscellaneous Charge Billed Currency',
        'Shipment Miscellaneous Charge USD',
        'Shipment Duty and Tax Charge USD',
        'Shipment Discount Amount USD',
        'Shipment Discount Billed Currency',
        'Shipment Duty and Tax Charge Billed Currency',
        'Net Charge Billed Currency',
        'Net Charge Amount USD',
        'Pieces in Shipment',
        'Shipment Rated Weight(Pounds)',
        'Original Weight (Pounds)',
        'Pricing Zone',
        'Dimmed Height (in)',
        'Dimmed Width (in)',
        'Dimmed Length (in)',
        'Shipment Declared Value Amount',
        'Customs Value',
        'Exchange Rate to USD'
      ]);
      temp = convertStringToDate(temp, [
        'Shipment Date (mm/dd/yyyy)',
        'Shipment Delivery Date (mm/dd/yyyy)',
        'Invoice Date (mm/dd/yyyy)'
      ]);
      temp['Transit Days'] = getTransitDays(
        temp['Shipment Date (mm/dd/yyyy)'],
        temp['Shipment Delivery Date (mm/dd/yyyy)']
      );
      temp['Carrier'] = 'FedEx';
      scrubbed.push(temp);
    });
    return scrubbed;
  }

  return (
    <React.Fragment>
      {spinner}
      <div>
        <CSVReader
          config={{
            dynamicTyping: false,
            header: true,
            skipEmptyLines: true
          }}
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <React.Fragment>
              <Form.Group controlId="formFileInput">
                <Form.Label>File Name:</Form.Label>
                <Form.Control
                  name="fileInput"
                  // onChange={handleChange}
                  type="number"
                  placeholder={file ? file.name : '*.csv'}
                  variant="primary"
                  onClick={handleOpenDialog}
                />
                <Form.Label>Small Parcel Carrier:</Form.Label>
                <Form.Control
                  as="select"
                  name="carrier"
                  // onChange={handleCarrierChange}
                >
                  <option value="FedEx">FedEx</option>
                  <option value="UPS">UPS</option>
                </Form.Control>
                <br></br>
                <Form.Check
                  // onChange={handleCheckboxChange}
                  type="checkbox"
                  label="Show Table"
                  name="checkBox"
                />
                <br></br>
                <Button
                  onClick={sessionState.haveUploadData ? asyncUpload : null}
                  variant="primary"
                  type="submit"
                >
                  {/* {' '} */}
                  Upload to PaketWatch
                </Button>
              </Form.Group>
            </React.Fragment>
          )}
        </CSVReader>
      </div>
    </React.Fragment>
  );

  function convertValueToNumber(object, fields) {
    let ret = object;
    fields.forEach((field) => {
      ret[field] = isNaN(Number(ret[field])) ? 0 : Number(ret[field]);
    });
    return ret;
  }

  function convertStringToDate(object, keys) {
    let ret = object;
    keys.forEach((key, i) => {
      ret[key] = new Date(ret[key]);
    });
    return ret;
  }

  function getTransitDays(shipDate, deliveryDate) {
    let daysElapsed = [];
    while (deliveryDate > shipDate) {
      daysElapsed.push(deliveryDate.getDay());
      deliveryDate.setTime(deliveryDate.getTime() - 24 * 60 * 60 * 1000);
    }
    daysElapsed.filter((day) => day !== 0 && day !== 6);
    return daysElapsed.length;
  }
}
