import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

class KpiCard extends Component {
  render() {
    return (
      <Card
        style={{ width: '21rem', height: '16rem' }}
        className="card align-items-center shadow-lg p-3 mb-3 bg-white rounded"
      >
        <Card.Body>
          <Card.Title className="text-center">
            <h3>
              <b>{this.props.title}</b>
            </h3>
          </Card.Title>
          <Card.Text className="text-center">
            <i>{this.props.text}</i>
            <br />
            <h1>
              <b>{this.props.value}</b>
            </h1>
          </Card.Text>
          <Card.Link href={this.props.link}>more</Card.Link>
        </Card.Body>
      </Card>
    );
  }
}

export default KpiCard;
