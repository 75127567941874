import React, { useRef, useEffect, useState } from 'react';

const Isomer = window.Isomer;
let Point = Isomer.Point;
let Shape = Isomer.Shape;
let Color = Isomer.Color;
let Path = Isomer.Path;
let palletColor = new Color(0x15, 0x89, 0xff); //#1589FF  Dodger Blue
let cartonColor = new Color(205, 133, 63); // brown
let black = new Color(0, 0, 0);

const PalletRendering = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    displayPalletDetails(
      props.name,
      canvas,
      -0.016773162939297 * props.width + 24.709265175719
    );
  }, [props.name]);
  // console.log(props.height);
  return (
    <canvas
      width={props.width}
      height={props.height}
      ref={canvasRef}
      {...props}
    />
  );
};

export default PalletRendering;

function displayPalletDetails(option, canvas, imageDivisor) {
  // const Isomer = window.Isomer;
  // let Point = Isomer.Point;
  // let Shape = Isomer.Shape;
  // let Color = Isomer.Color;
  // let Path = Isomer.Path;
  // let palletColor = new Color(0x15, 0x89, 0xff); //#1589FF  Dodger Blue
  // let cartonColor = new Color(205, 133, 63); // brown
  // let black = new Color(0, 0, 0);

  let iso = new Isomer(canvas);
  iso.canvas.clear();
  // iso.draw(true);

  // draw PALLET
  let palletOrigin = Point(0, 0, -option.pallet.height / imageDivisor);
  let palletSketch = new Shape.Prism(
    palletOrigin,
    option.pallet.length / imageDivisor,
    option.pallet.width / imageDivisor,
    option.pallet.height / imageDivisor
  );
  // iso.add(palletSketch, palletColor, true, '', black);
  drawCubeWithEdges(iso, palletSketch, palletColor);

  // draw Cartons
  for (let k = 0; k < option.numLayers; k++) {
    for (let i = 0; i < option.cCol; i++) {
      let refPoint = new Point(
        option.pallet.length / imageDivisor -
          (i * option.length) / imageDivisor,
        option.pallet.width / imageDivisor,
        k === option.numLayers - 1
          ? (k * option.height) / imageDivisor +
            option.height / imageDivisor / 2
          : (k * option.height) / imageDivisor
      );
      let box = new Shape.Prism(
        refPoint,
        -option.length / imageDivisor,
        -option.width / imageDivisor,
        option.height / imageDivisor
      );
      drawCubeWithEdges(iso, box, cartonColor, true);
      // iso.add(box, cartonColor, true, '', new Color(0, 0, 0));
    }
    for (let i = option.bCol; i > 0; i--) {
      for (let j = option.bRow; j > 0; j--) {
        let refPoint = new Point(
          ((i - 1) * option.width) / imageDivisor +
            (option.length / imageDivisor) * option.aCol,
          ((j - 1) * option.length) / imageDivisor,
          k === option.numLayers - 1
            ? (k * option.height) / imageDivisor +
              option.height / imageDivisor / 2
            : (k * option.height) / imageDivisor
        );
        let box = new Shape.Prism(
          refPoint,
          option.width / imageDivisor,
          option.length / imageDivisor,
          option.height / imageDivisor
        );
        drawCubeWithEdges(iso, box, cartonColor, false);
        // iso.add(box, cartonColor, false, '', black);
      }
    }
    for (let i = option.aCol; i > 0; i--) {
      for (let j = option.aRow; j > 0; j--) {
        let refPoint = new Point(
          ((i - 1) * option.length) / imageDivisor,
          ((j - 1) * option.width) / imageDivisor,
          k === option.numLayers - 1
            ? (k * option.height) / imageDivisor +
              option.height / imageDivisor / 2
            : (k * option.height) / imageDivisor
        );
        let box = new Shape.Prism(
          refPoint,
          option.length / imageDivisor,
          option.width / imageDivisor,
          option.height / imageDivisor
        );
        drawCubeWithEdges(iso, box, cartonColor, false);
        // iso.add(box, cartonColor, false, '', black);
      }
    }
  }
}

function drawCubeWithEdges(iso, cube, color, rotate) {
  // console.log(cube);
  if (rotate) {
    iso.add(cube, color);
    iso.add(
      new Path([
        cube.paths[1].points[0],
        cube.paths[1].points[1],
        cube.paths[1].points[0]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[1].points[1],
        cube.paths[1].points[2],
        cube.paths[1].points[1]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[1].points[2],
        cube.paths[1].points[3],
        cube.paths[1].points[2]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[1].points[3],
        cube.paths[1].points[0],
        cube.paths[1].points[3]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[3].points[0],
        cube.paths[3].points[1],
        cube.paths[3].points[0]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[3].points[1],
        cube.paths[3].points[2],
        cube.paths[3].points[1]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[3].points[2],
        cube.paths[3].points[3],
        cube.paths[3].points[2]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[3].points[3],
        cube.paths[3].points[0],
        cube.paths[3].points[3]
      ]),
      black
    );
  } else {
    iso.add(cube, color);
    iso.add(
      new Path([
        cube.paths[0].points[0],
        cube.paths[0].points[1],
        cube.paths[0].points[0]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[0].points[1],
        cube.paths[0].points[2],
        cube.paths[0].points[1]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[0].points[2],
        cube.paths[0].points[3],
        cube.paths[0].points[2]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[0].points[3],
        cube.paths[0].points[0],
        cube.paths[0].points[3]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[2].points[0],
        cube.paths[2].points[1],
        cube.paths[2].points[0]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[2].points[1],
        cube.paths[2].points[2],
        cube.paths[2].points[1]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[2].points[2],
        cube.paths[2].points[3],
        cube.paths[2].points[2]
      ]),
      black
    );
    iso.add(
      new Path([
        cube.paths[2].points[3],
        cube.paths[2].points[0],
        cube.paths[2].points[3]
      ]),
      black
    );
  }
  iso.add(
    new Path([
      cube.paths[5].points[0],
      cube.paths[5].points[1],
      cube.paths[5].points[0]
    ]),
    black
  );
  iso.add(
    new Path([
      cube.paths[5].points[1],
      cube.paths[5].points[2],
      cube.paths[5].points[1]
    ]),
    black
  );
  iso.add(
    new Path([
      cube.paths[5].points[2],
      cube.paths[5].points[3],
      cube.paths[5].points[2]
    ]),
    black
  );
  iso.add(
    new Path([
      cube.paths[5].points[3],
      cube.paths[5].points[0],
      cube.paths[5].points[3]
    ]),
    black
  );
}
